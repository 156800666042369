import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link, graphql } from 'gatsby';

const BlogPage = ( {data} ) => {

  return (
    <Layout>
      <SEO 
        title="Blog"
        description="The land of the blogs. This is where the blogs are. Feel free to browse."
      />

    <div className="page-heading-box">
      <h1>BLOG</h1>
    </div>

    <div className="container">
      {data.allMarkdownRemark.edges.map(post =>  (
        <div key={ post.node.id } className="container-item">
          <h3>{post.node.frontmatter.title}</h3>
          <small><em>published on</em> {post.node.frontmatter.date}</small>
          <p>{post.node.frontmatter.excerpt}</p>
          <Link to={post.node.frontmatter.path}>
            Read More &rarr;
          </Link>
          <hr className="blogpage-hr"/>

        </div>
      ))}
    </div>

    
  </Layout>
  )
}

export const query = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            image
            date(formatString: "MMMM DD, YYYY")
            tags
            excerpt
          }
        }
      }
    }
  }
`

export default BlogPage